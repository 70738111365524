import keys from 'lodash/keys'

export const setFormData = (
  values: {
    card_date: string
    credit_card: Record<string, any>
    [key: string]: any
  },
  creditCardFields: Record<string, any>,
) => {
  const extraParams = [
    'affiliate',
    'hold_card_30_days',
    'repeat_order',
    'terms_and_agreement',
  ]
  const cardDates = values['card_date'].split('/')
  const dateValues = { month: cardDates[0], year: `20${cardDates[1]}` }
  keys(creditCardFields).forEach((k) => {
    const hiddenEl: HTMLInputElement | null = document.querySelector(
      `input[name='${creditCardFields[k]}'`,
    )
    const valueKey = creditCardFields[k].match(/\[(.*?)\]/)[1]
    if (hiddenEl) {
      hiddenEl.value = values.credit_card[valueKey]
    }
  })
  extraParams.forEach((param) => {
    const hiddenEl: HTMLInputElement | null = document.querySelector(
      `input[name='data[${param}]'`,
    )
    if (hiddenEl) {
      hiddenEl.value = values[param] || false
    }
  })
  Object.keys(dateValues).forEach((df: string) => {
    const hiddenEl: HTMLInputElement | null = document.querySelector(
      `input[name='credit_card[${df}]']`,
    )
    if (hiddenEl) {
      hiddenEl.value = dateValues[df as keyof typeof dateValues]
    }
  })
}
