export const getVariants = (
  variants: { option_values: { id: string }[] }[],
  selectedOptions: { id: string }[],
) => {
  return variants.filter((variant) => {
    return (
      variant.option_values.filter((ov) => {
        return selectedOptions.filter((so) => so.id === ov.id).length
      }).length === selectedOptions.length
    )
  })
}
