import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

import { useCompany } from '../../context/CompanyContext'
import { fontFamily, StyledButton } from '../common/styled'

type formValues = {
  name: string
  phone: string
  email: string
  messageBody: string
}

interface IProps {
  collect?: string[]
  buttonText?: string
  handleSubmit: Function
  submitting?: boolean
  formSubmitted?: boolean
  textColor?: string
  backgroundColor?: string
  buttonBackgroundColor?: string
  buttonTextColor?: string
}

const StyledAnimatedWrapper = styled(animated.div)`
  background: transparent;
  box-sizing: border-box;
  border-radius: 14px 14px 0px 14px;
`

const StyledInputWrapper = styled.div`
  position: relative;
  margin: 0 0 14px;
  :last-of-type {
    margin: 0;
  }
  input,
  textarea {
    width: 100%;
    outline: none;
    padding: 22px 20px;
    position: relative;
    border-radius: 8px;
    border: none;
    background-color: white;
  }
`

const StyledError = styled.div`
  color: red;
  font-size: 12px;
`

const StyledInput = styled.input`
  border: none;
`

const StyledTextarea = styled(StyledInput).attrs({
  as: 'textarea',
})<any>``

const StyledInfo = styled.div`
  color: ${(props) => props?.color ?? '#171b2b'};
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  margin: 1.5rem 0;
  font-family: ${fontFamily.REGULAR};
`

const PopupSubmitButton = styled(StyledButton)<{ background?: string }>`
  color: ${(props) => props?.color ?? '#ffffff'} !important;
  margin-bottom: 30px;
`

const StyledLoader = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Form = (props: IProps) => {
  const company = useCompany()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)

    setTimeout(function () {
      document.getElementById('FluidChatNameField')?.focus()
    }, 1000)

    return () => {
      setIsVisible(false)
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formValues>()

  const onSubmit = handleSubmit((data) => {
    props.handleSubmit(data)
  })

  const slideLeft = useTransition(isVisible, {
    from: { x: 100, opacity: 0 },
    enter: { x: 0, opacity: 1, delay: 300 },
    leave: { x: 100, opacity: 0 },
  })

  const autoResizeArea = (e: any) => {
    if (
      e.target.offsetHeight < 200 &&
      (e.target.offsetHeight, e.target.scrollHeight)
    ) {
      e.target.style.height = `${e.target.scrollHeight}px`
    }
  }

  return (
    <form onSubmit={onSubmit} id="FluidChatForm">
      {slideLeft((style, item) => {
        return item ? (
          <StyledAnimatedWrapper style={style}>
            {props.collect && props.collect.includes('name') && (
              <StyledInputWrapper>
                <StyledInput
                  {...register('name', {
                    required: 'Enter your name.',
                    pattern: {
                      value: /^[A-Za-z\s]+$/i,
                      message:
                        'Enter name without special characters and digits.',
                    },
                  })}
                  type="text"
                  placeholder="Name"
                  id="FluidChatNameField"
                  style={{ fontFamily: fontFamily.REGULAR }}
                ></StyledInput>
                {errors.name && (
                  <StyledError>{errors.name.message}</StyledError>
                )}
              </StyledInputWrapper>
            )}
            {props.collect && props.collect.includes('phone') && (
              <StyledInputWrapper>
                <StyledInput
                  {...register('phone', {
                    required: 'Enter your phone.',
                    pattern: {
                      value: /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/i,
                      message: 'Enter a valid phone number.',
                    },
                  })}
                  type="text"
                  placeholder="Phone"
                  style={{ fontFamily: fontFamily.REGULAR }}
                ></StyledInput>
                {errors.phone && (
                  <StyledError>{errors.phone.message}</StyledError>
                )}
              </StyledInputWrapper>
            )}
            {props.collect && props.collect.includes('email') && (
              <StyledInputWrapper>
                <StyledInput
                  {...register('email', {
                    required: 'Enter your email.',
                    pattern: {
                      value:
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
                      message: 'Enter a valid email.',
                    },
                  })}
                  type="text"
                  placeholder="Email"
                  style={{ fontFamily: fontFamily.REGULAR }}
                ></StyledInput>
                {errors.email && (
                  <StyledError>{errors.email.message}</StyledError>
                )}
              </StyledInputWrapper>
            )}
            {props.collect && props.collect.includes('message') && (
              <StyledInputWrapper>
                <StyledTextarea
                  {...register('messageBody', {
                    required: 'Enter your message.',
                  })}
                  placeholder="Message"
                  rows={1}
                  onKeyDown={(e: React.KeyboardEvent) => autoResizeArea(e)}
                  style={{ fontFamily: fontFamily.REGULAR }}
                ></StyledTextarea>
                {errors.messageBody && (
                  <StyledError>{errors.messageBody.message}</StyledError>
                )}
              </StyledInputWrapper>
            )}
          </StyledAnimatedWrapper>
        ) : (
          ''
        )
      })}
      <StyledInfo color={props?.textColor}>
        By submitting, you authorize {company?.name} to send text messages with
        offers & other information, possibly using automated technology, to the
        number you provided. Message/data rates apply. Consent is not a
        condition of purchase.
      </StyledInfo>
      <PopupSubmitButton
        className="lg-button"
        background={props?.buttonBackgroundColor ?? company?.color}
        color={props?.buttonTextColor}
      >
        {props.buttonText}
        {props.submitting ? <StyledLoader></StyledLoader> : ''}
      </PopupSubmitButton>
    </form>
  )
}

export default Form
