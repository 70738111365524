import styled from 'styled-components'
import { animated } from 'react-spring'

type StyledTitleProps = {
  fontSize?: string
  fontWeight?: string
  fontFamily?: string
  lineHeight?: string
  color?: string
}

type ButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void // to handle onClick functions
  children?: React.ReactNode // make the component able to receive children elements
  color?: string // two styling options (you can create as many as you want)
  disabled?: boolean // make the button disabled or not
}

type LoaderProps = {
  color?: string
  width?: string
  height?: string
}

type ErrorProps = {
  bgColor?: string
}

type StyledBreakLineProps = {
  color?: string
  width?: string
  marginTop?: string
  marginBottom?: string
}
type StyledPopupProps = {
  blur?: boolean
  width?: string
  display?: string
}
export const FluidChatWrapperStyled = styled.div`
  /* font-family: 'Eina03-Regular, sans-serif'; */
  --ff-eina-regular: 'Eina03-Regular', sans-serif;
  --ff-eina-semibold: 'Eina03-SemiBold', sans-serif;
  --ff-eina-bold: 'Eina03-Bold', sans-serif;
  font-family: var(--ff-eina-regular);

  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  input,
  select,
  textarea,
  button,
  label,
  p {
    font-family: var(--ff-eina-regular);
  }
  button,
  input,
  select,
  textarea {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: none;
    outline: none;
    height: unset;
    min-height: unset;
    background-color: transparent;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  blockquote {
    font-family: var(--ff-eina-semibold);
    margin: 0;
    padding: 0;
  }
  button {
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    align-items: center;
    text-transform: capitalize;
    border-radius: 4px;
    &.lg-button {
      height: 65px;
      line-height: 1.3;
      padding: 13px 24px;
      font-size: 16px;
      font-family: var(--ff-eina-bold) !important;
      color: #fff;
      text-align: center;
      letter-spacing: -0.430769px;
      border-radius: 8px;
      &.vr {
        flex-direction: column;
      }
    }
  }
`

export const StyledChatCloseImage = styled.img`
  display: none;
  position: relative;
`

export const StyledChatIcon = styled.div`
  width: 21px;
  height: 21px;
  position: relative;
  ::after,
  ::before {
    content: '';
    position: absolute;
    background-color: white;
    border-radius: 50% 50% 50% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
`

export const BubbleIcon = styled.img`
  position: relative;
  max-width: 24px;
  width: 100%;
`

export const StyledContainer = styled.div`
  z-index: 999;
  right: 40px;
  bottom: 30px;
  position: fixed;
  @media screen and (max-width: 600px) {
    right: 25px;
    bottom: 25px;
  }
  &.fluid-chat-open {
    ${StyledChatIcon}::after,
    ${StyledChatIcon}::before {
      content: '';
      top: -3px;
      left: 9px;
      width: 3.25px;
      height: 26px;
      position: absolute;
      border-radius: 1px;
      background-color: white;
    }
    ${StyledChatIcon}::before {
      transform: rotate(-45deg);
    }
    ${StyledChatIcon}::after {
      transform: rotate(45deg);
    }
    ${StyledChatCloseImage} {
      display: block;
    }
    ${BubbleIcon} {
      display: none;
    }
  }
`

export const StyledAnimatedMessageButton = styled(animated.div)<any>`
  width: 60px;
  height: 60px;
  color: ${(props) => props.color || '#2264ea'};
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.2);
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: currentColor;
    filter: saturate(3) contrast(0.7);
  }
`

export const StyledClose = styled.button<ButtonProps>`
  &&& {
    width: 32px;
    height: 32px;
    top: 28px;
    right: 17px;
    position: absolute;
    border: 0;
    transform: translateY(-50%);
    color: #394046;
    @media screen and (max-width: 768px) {
      display: none;
    }

    :hover {
      cursor: pointer;
    }
    ::before,
    ::after {
      content: '';
      width: 2.25px;
      height: 14px;
      position: absolute;
      background: currentColor;
      transform: rotate(45deg);
    }
    ::after {
      transform: rotate(-45deg);
    }
  }
`

export const ErrorClose = styled(StyledClose)`
  color: #818596 !important;
  position: relative !important;
  flex: 0 0 25px;
  max-width: 25px;
  height: 12px !important;
  top: 4px !important;
  right: 0 !important;
  transform: none !important;
  @media screen and (max-width: 768px) {
    display: block !important;
  }
`

export const ErrorContainer = styled.div<ErrorProps>`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.bgColor || '#F04438'};
  color: #ffffff;
  padding: 8px 10px;
  border-radius: 8px;
  margin-top: 24px;
`
export const ErrorMessageContainer = styled.div`
  color: #171b2b;
  padding: 0 8px;
  flex: 0 0 calc(100% - 50px);
`

export const ErrorIconWrapper = styled.div`
  flex: 0 0 25px;
  max-width: 25px;
  max-height: 22px;
`

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cacaca;
  line-height: 19px;
  font-size: 14px;
  font-family: 'Eina03-Regular', sans-serif;
  ::placeholder {
    color: #999fb5;
    font-family: 'Eina03-Regular', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }

  :-ms-input-placeholder {
    color: #999fb5;
    font-family: 'Eina03-Regular', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }

  ::-ms-input-placeholder {
    color: #999fb5;
    font-family: 'Eina03-Regular', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
  &:focus {
    background: #ffffff;
    border: 1px solid rgba(12, 124, 246, 0.5);
    border-radius: 4px;
  }
  -webkit-box-shadow: 0 0 0px 1000px white inset;
`

export const StyledLoader = styled.div<LoaderProps>`
  width: ${(props) => props.width || '16px'};
  height: ${(props) => props.height || '16px'};
  margin-left: 10px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid ${(props) => props.color || '#0C7CF6'};
  border-bottom: 3px solid ${(props) => props.color || '#0C7CF6'};
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StyledPopup = styled.div<StyledPopupProps>`
  top: 0;
  left: 0;
  width: ${(props) => props.width || '100vw'};
  height: 100vh;
  position: fixed;
  display: ${(props) => props.display || 'flex'};
  align-items: center;
  justify-content: center;
  backdrop-filter: ${(props) => (props.blur ? 'blur(3px)' : 'none')};
`

export const StyledPopupBackdrop = styled.div`
  height: 100%;
  width: 100%;
  position: inherit;
  cursor: pointer;
`

export const StyledTitle = styled.div<StyledTitleProps>`
  font-size: ${(props) => props.fontSize || '24px'};
  font-weight: ${(props) => props.fontWeight || '700'};
  line-height: ${(props) => props.lineHeight || '33px'};
  letter-spacing: -0.738462px;
  color: ${(props) => props.color || '#171b2b'};
  font-family: ${(props) => props.fontFamily || 'Eina03-Regular, sans-serif'};
  font-style: 'normal';
`

export const StyledBreakLine = styled.div<StyledBreakLineProps>`
  border-style: solid;
  border-color: ${(props) => props.color || '#e6ecf0'};
  border-width: ${(props) => props.width || '1px'};
  margin-top: ${(props) => props.marginTop || '20px'};
  margin-bottom: ${(props) => props.marginBottom || '21px'};
`

export const StyledCartCount = styled.span`
  border-radius: 50%;
  font-weight: bold;
  line-height: 29px;
  text-align: center;
  width: 28px;
  position: absolute;
  bottom: 38px;
  background: #ef1a65;
  color: white;
  right: -8px;
`
