// @ts-nocheck
import styled from 'styled-components'
import { StyledInput as StyledInputField } from '../../../styled'

export const fontFamily = {
  REGULAR: 'Eina03-Regular, sans-serif',
  BOLD: 'Eina03-Bold, sans-serif',
  SEMIBOLD: 'Eina03-SemiBold, sans-serif',
}

interface StyledGridProps {
  size?: number
}

interface StyledButtonProps {
  marginTop?: number | string
}

export const StyledProductContainer = styled.div`
  background: white;
  padding: 10px 40px 20px 45px;
  @media screen and (max-width: 600px) {
    padding: 10px 20px 20px 25px;
  }
`

export const StyledLoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 999999;
  top: 0;
  display: flex;
  margin: auto;
  justify-content: center;
  margin-right: 39px;
  padding-top: 230px;
`

export const StyledTitle = styled.div<StyledTitleProps>`
  font-size: ${(props) => props.fontSize || '24px'};
  font-weight: ${(props) => props.fontWeight || '700'};
  line-height: ${(props) => props.lineHeight || '33px'};
  letter-spacing: 0.2px;
  color: ${(props) => props.color || '#171b2b'};
  font-family: ${(props) => props.fontFamily || fontFamily.REGULAR};
  font-style: normal;
  word-break: break-all;
  flex-shrink: ${(props) => props?.flexShrink || undefined};
`
export const StyledTitleWithTest = styled(StyledTitle).attrs((props) => ({
  'data-cy': props.dataCy,
}))`
  font-family: ${fontFamily.SEMIBOLD};
  font-weight: 600;
  line-height: 120%;
  &.cart-details {
    line-height: 22px;
    color: #171b2b;
  }
  &.cart-payment-detail {
    font-size: 14px;

    color: #999fb5;
  }
  &.cart-payment-detail-total {
    font-size: 16px;
    color: black;
  }
`

export const StyledInputLabel = styled.div`
  font-family: ${fontFamily.REGULAR};
  font-size: 14px;
  line-height: 130%;
  color: #999fb5;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`

export const StyledCardTitle = styled.div`
  font-family: ${fontFamily.BOLD};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -1px;
  margin-top: 8px;
`
export const StyledCardSubTitle = styled(StyledCardTitle)`
  color: #158c2d;
`

export const StyledCardFooterText = styled.p`
  font-family: ${fontFamily.REGULAR};
  line-height: 19px;
  letter-spacing: -0.492308px;
  color: #171b2b;
`

export const StyledCardFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    display: block;
  }
`

export const StyledFlexBox = styled.div`
  display: flex;
`

export const StyledShippingDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
  gap: 16px;
  @media screen and (min-width: 600px) {
    flex-wrap: no-wrap;
  }
`

export const StyledInput = styled(StyledInputField)`
  border: 1px solid rgba(153, 159, 181, 0.5);
  padding: 8px;
  width: 100%;
  line-height: 19px;
  /* font-family: ${fontFamily.REGULAR};
  &[type='text'],
  &[type='number'],
  &[type='email'],
  &[type='url'],
  &[type='password'],
  &[type='reset'],
  &[type='search'],
  &[type='tel'] {
    border: 1px solid rgba(153, 159, 181, 0.5);
    padding: 8px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    font-family: ${fontFamily.REGULAR};
    font-size: 14px;
    line-height: 19px;
    border-radius: 4px;
  } */
`

export const StyledIcon = styled.div`
  position: relative;
  top: ${(props) => props.top || '-28px'};
  left: ${(props) => props.left || '297px'};
  width: 23%;
  cursor: pointer;
`

export const StyledInputRequiredSpan = styled.span`
  color: red;
  margin-left: 5px;
`

export const StyledInputContainer = styled.div`
  position: relative;
`

export const StyledInputWrapper = styled.div`
  position: relative;
`

export const StyledErrorMessage = styled.span`
  color: #d83856;
  top: -1px;
  left: 15px;
  line-height: 1;
  font-size: 11px;
  position: absolute;
  background: white;
  transform: translateY(-50%);
  max-width: 75%;
  padding: 0 4px;
`

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.size ? `${100 / props.size}% `.repeat(props.size) : props.columns};
  grid-column-gap: ${(props) =>
    props.columnGap ? `${props.columnGap}px` : '16px'};
  grid-row-gap: ${(props) => (props.rowGap ? `${props.rowGap}px` : '16px')};
`

export const StyledItemGrid = styled(StyledGrid)`
  @media only screen and (max-width: 480px) {
    gap: 10px 15px;
    grid-template-columns: 80px 1fr;
    .lg-device {
      display: none;
    }
  }
`

export const StyledCartItem = styled.div`
  .mobile-device {
    display: none;
    padding-top: 8px;
    @media only screen and (max-width: 480px) {
      display: block;
    }
  }
`

export const StyledInputGroupLabel = styled.p`
  font-family: ${fontFamily.REGULAR};
  font-size: 18px;
  line-height: 25px;
  color: #999fb5;
`
export const StyledClose = styled.button`
  width: 24px;
  height: 24px;
  top: 34px;
  right: 24px;
  position: absolute;
  border: 0;
  background: transparent;
  transform: translateY(-50%);
  color: #999fb5;
  cursor: pointer;

  ::before,
  ::after {
    content: '';
    width: 2.25px;
    height: 18px;
    top: 3px;
    position: absolute;
    background: currentColor;
    transform: rotate(45deg);
  }
  ::after {
    transform: rotate(-45deg);
  }
  @media screen and (max-width: 480px) {
    &.cart-close {
      top: 24px;
      ::before,
      ::after {
        height: 28px;
      }
    }
  }
`
export const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) => props.background || '#0c7cf6'} !important;
  font-family: ${(props) => props.fontFamily || fontFamily.REGULAR} !important;
  color: ${(props) => props?.color ?? '#ffffff'};
  border-color: transparent;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '65px'};
  margin-top: ${(props) => props.marginTop && '40px !important'};
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.6;
  }
`

export const StyledFormSubmitBtn = styled(StyledButton)`
  margin-bottom: 30px;
`

export const StyledSubscribeButton = styled(StyledButton)`
  @media screen and (max-width: 600px) {
    width: 98%;
    margin: 10px 0px 10px 0px !important;
  }
`

export const StyledCardTopContainer = styled.div`
  height: 125px;
  @media screen and (max-width: 600px) {
    height: 85px;
  }
`
export const StyledForm = styled.form`
  padding: 0 45px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`

export const StyledResponsive = styled.form`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`
export const StyledCardVariantContainer = styled.div`
  display: flex;
  gap: 20px;
  font-family: ${fontFamily.REGULAR};
  line-height: 130%;
  color: rgba(105, 109, 128, 0.5);
  @media screen and (max-width: 600px) {
    min-height: 46px;
  }
`

export const StyledLabel = styled.div`
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #8e8e8e;
  font-size: 14px;
  font-family: ${fontFamily.REGULAR};
`
export const StyledCircleText = styled.div`
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: -0.430769px;
  color: #ffffff !important;
  margin-top: 16px;
`
export const StyledCircle = styled.div`
  border-radius: 50%;
  background-color: #0c7cf6;
  height: 21px;
  width: 21px;
  text-align: center;
`

export const StyledCardImage = styled.img`
  width: 167px;
  height: 205px;
  left: 272px;
  top: 531px;
  background: rgba(34, 100, 234, 0.15);
  border-radius: 10px 0px 0px 10px;
`

export const StyleTextTruncate = styled.div`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${(props) => props.size || 2};
  font-family: ${fontFamily.REGULAR};
  font-size: 12px;
  line-height: 14.4px;
  min-height: 2.2em;
  color: #999fb5;
  word-break: break-all;
  letter-spacing: -0.492308px;
`

export const StyledCardContainer = styled(StyledGrid)`
  margin-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 14px 16px -7px rgb(14 15 59 / 12%);
  border-radius: 10px;
`

export const StyledCardProductOption = styled.div``

export const StyledLink = styled.a`
  color: #0c7cf6;
  text-decoration: none;
`
