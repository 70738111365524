const roundAmount = (number: any) =>
  isNaN(number) ? 0 : Number(parseFloat(number || 0).toFixed(2))

export const threeDsTwoParams = (
  token: string,
  visitor: any,
  affiliate: any,
  total: number,
  getValues: (key: string) => any,
) => {
  const browser_size = window.screen.width > 600 ? '05' : '01'
  const acceptHeader =
    'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8'
  const browser_info = window.Spreedly.ThreeDS.serialize(
    browser_size,
    acceptHeader,
  )

  const params = {
    token,
    payment: {
      token,
      amount: roundAmount(total * 100),
    },
    three_ds_two: {
      attempt_3dsecure: true,
      three_ds_version: 2,
      browser_info,
    },
    user: {
      email: getValues('email'),
    },
    shipping_address: {
      name: getValues('data.full_name'),
      country: getValues('credit_card.shipping_country'),
      address1: getValues('credit_card.shipping_address1'),
      address2: getValues('credit_card.shipping_address2'),
      city: getValues('credit_card.shipping_city'),
      zip: getValues('credit_card.shipping_zip'),
      state: getValues('credit_card.shipping_state'),
    },
  }

  return {
    transaction: params,
    visitor,
    affiliate,
  }
}
