import { getTotal } from './'

export const getCartElements = (
  items: {
    quantity: number
    product: { variants: { variant_countries: any[]; id: string }[] }
    variant: { id: string }
  }[],
  country: { iso_name: string } | null = null,
  isTax: boolean,
  isShipping: boolean,
  isRepeatOrder: boolean,
) => {
  let itemTotal = 0.0
  let tax = 0.0
  let shipping = 0.0
  items?.forEach((crt) => {
    const variantDetails = crt?.product?.variants?.find(
      (i) => i.id === crt?.variant?.id,
    )
    const variantCountryDetails = variantDetails?.variant_countries?.find(
      (t) => t?.country?.iso_name === country?.iso_name,
    )
    if (isTax) tax = tax + variantCountryDetails?.tax * crt?.quantity
    if (isShipping) shipping = shipping + variantCountryDetails?.shipping * 1
    itemTotal =
      itemTotal +
      (isRepeatOrder
        ? variantCountryDetails?.subscription_price
        : variantCountryDetails?.price) *
        crt?.quantity
  })
  const totals = getTotal({
    price: itemTotal,
    shipping: shipping,
    tax: tax,
  })
  return { tax, shipping: shipping, total: totals, itemsTotal: itemTotal }
}
