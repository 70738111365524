import get from 'lodash/get'

export const borderStyle = (errors: Record<string, any>, name: string) => {
  const inputErrors =
    (name.match(/credit_card/) ? errors.credit_card : errors) || {}

  const importantBorder = [
    'shipping_address1',
    'shipping_address2',
    'shipping_city',
    'shipping_state',
    'shipping_zip',
  ]
  const key = name.match(/credit_card/)
    ? name.match(/\[(.*?)\]/)?.[1] || ''
    : name
  if (inputErrors[key]) {
    const important = importantBorder.includes(key) ? '! important' : ''
    return {
      border: `1px solid red ${important}`,
      outline: 'none',
    }
  } else if (get(errors, name)) {
    return {
      border: '1px solid red',
      outline: 'none',
    }
  }
  return {}
}
