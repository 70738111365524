import { useCompany } from '../../../context/CompanyContext'
import { StyledCompanyLogo } from '../Modal/styled'
import { StyledAvatar } from './styled'

const CompanyAffiliateLogo = () => {
  const company = useCompany()

  const showCompanyLogo = company?.chat?.show_company_logo
  const affiliateImage = company?.chat?.image

  return affiliateImage ? (
    <StyledAvatar
      style={{ display: 'inline' }}
      src={company?.chat?.image}
      alt="MP"
    />
  ) : showCompanyLogo ? (
    <StyledCompanyLogo
      src={company?.logo}
      alt={`${company?.name} Logo`}
      style={{ display: 'inline-block' }}
    />
  ) : (
    <StyledAvatar
      style={{ display: 'inline' }}
      src={company?.chat?.fill_image}
      alt="MP"
    />
  )
}

export default CompanyAffiliateLogo
