import { createContext, useContext } from 'react'
import type { Company } from '../types/Company'

const CompanyContext = createContext<Company>({
  isLoaded: false,
  popupsChecked: false,
  showCartCheckoutPopup: false,
  color: '#000000',
  chatOpen: false,
  showBanner: false,
  prompt: false,
  currentUserToken: '',
  settings: {},
  apiUrlHost: '',
  autoResponse: {},
  message: {
    name: '',
    phone: '',
    email: '',
    messageBody: '',
  },
  chat: {},
  formSubmitted: false,
  messageDelivered: false,
  errorSendingMessage: false,
  popups: [],
  defaultCountry: {},
} as Company)

const CompanyProvider: React.FC<{ company: Company }> = ({
  company,
  children,
}) => {
  return (
    <CompanyContext.Provider value={company}>
      {children}
    </CompanyContext.Provider>
  )
}

const useCompany = () => {
  const context = useContext(CompanyContext)
  if (context === undefined) {
    throw new Error('useCompany must be used within a CompanyProvider')
  }
  return context
}

export { CompanyProvider, useCompany }
