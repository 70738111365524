import { borderStyle } from './borderStyle'
import { cartItemApiRoute } from './cartItemApiRoute'
import { cookieOption } from './cookieOption'
import { defaultCheckoutValue } from './defaultCheckoutValue'
import { formatCardDate } from './formatCardDate'
import { formatPrice } from './formatPrice'
import { getCartElements } from './getCartElements'
import { getCartTotal } from './getCartTotal'
import { getFullYear } from './getFullYear'
import { getTotal } from './getTotal'
import { getVariants } from './getVariants'
import { removeParamsFromURL } from './removeParamsFromURL'
import { setFormData } from './setFormData'
import { threeDsTwoParams } from './threeDsTwoParams'

const CHECKOUT_STEPS = {
  paymentMethodVerification: 'PAYMENT_METHOD_VERIFICATION',
  authorizeTransaction: 'AUTHORIZE_TRANSACTION',
  succeededTransaction: 'SUCCEEDED_TRANSACTION',
}

const cookiesKey = {
  cartId: 'cartId',
  cartItemsLength: 'cartItemsLength',
  cartToken: 'cartToken',
  fluid_v: 'fluid_v',
}

export {
  borderStyle,
  cartItemApiRoute,
  CHECKOUT_STEPS,
  cookieOption,
  cookiesKey,
  defaultCheckoutValue,
  formatCardDate,
  formatPrice,
  getCartElements,
  getCartTotal,
  getFullYear,
  getTotal,
  getVariants,
  removeParamsFromURL,
  setFormData,
  threeDsTwoParams,
}
