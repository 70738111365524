import styled from 'styled-components'
import { animated } from 'react-spring'
import { fontFamily } from '../../common/styled'

export const StyledAnimatedDiv = styled(animated.div)`
  right: 0;
  bottom: 80px;
  position: absolute;
  padding: 25px 0 0;
`
export const StyledCloseIcon = styled.div`
  cursor: pointer;
  width: max-content;
  margin: 0 0 10px auto;
  display: none;
  font-size: 10px;
  padding: 2px 10px;
  line-height: 130%;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: -2px 3px 6px 0 rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 600px) {
    display: block;
  }
  ${StyledAnimatedDiv}:hover & {
    display: block;
  }
`
export const StyledMiniModal = styled.div`
  width: 275px;
  min-height: 95px;
  display: flex;
  padding: 16px 25px 16px 16px;
  align-items: center;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 9px 18px 0 rgba(7, 6, 6, 0.1);
`
export const StyledMessageHead = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
export const StyledAvatar = styled.img`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.11);
`
export const StyledName = styled.div`
  color: #0e0f3b;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.369231px;
  font-family: ${fontFamily.SEMIBOLD};
`
export const StyledGreeting = styled.div`
  color: #171b2b;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.492308px;
  font-family: ${fontFamily.REGULAR};
`
export const StyledTriangle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  right: 20px;
  bottom: -7px;
  position: absolute;
  transform: rotate(45deg);
  background-color: white;
`
