import styled from 'styled-components'
import { animated } from 'react-spring'
import { fontFamily } from '../../common/styled'

export const StyledCrossIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @media screen and (min-width: 600px) {
    display: none;
  }
`

export const StyledChat = styled.div`
  width: 341px;
  max-width: 90vw;
  max-height: calc(95vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  bottom: 80px;
  overflow: hidden;
  position: absolute;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: ${fontFamily.REGULAR};
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    position: fixed;
    top: 73px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    max-width: unset;
    max-height: unset;
    z-index: 99999;
    border-radius: 0;
  }
`
export const StyledHead = styled.div`
  padding: 13px;
  color: ${(props) => props.color || '#2264ea'};
  text-align: center;
  z-index: 99;
  position: relative;
  flex-shrink: 0;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: currentColor;
    filter: saturate(3) contrast(0.7);
  }
`
export const StyledAvatar = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 50%;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.11);
`
export const StyledName = styled.div`
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.369231px;
  font-family: ${fontFamily.SEMIBOLD};
`
export const StyledContent = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 17px;
  background: #eff1f7;
`

export const StyledAnimatedText = styled(animated.div)<any>`
  color: ${(props) => props.color || '#2264ea'};
  width: 272px;
  max-width: 90%;
  margin: 0 0 14px;
  padding: 18px 25px;
  position: relative;
  box-sizing: border-box;
  border-radius: 14px 14px 14px 0;
  ::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.195;
    position: absolute;
    background: currentColor;
    border-radius: inherit;
    filter: hue-rotate(340deg) saturate(1.5);
  }
  p {
    margin: 0;
    color: #171b2b;
    z-index: 1;
    position: relative;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`
export const StyledFirstText = styled.div`
  font-size: 16px;
  color: #171b2b;
`

export const ReplyText = styled(StyledAnimatedText)`
  margin: 0 0 14px auto;
  border-radius: 14px 14px 0 14px;
`
export const StyledReceivedInfo = styled.div`
  display: flex;
  margin: -5px 0 14px;
  font-size: 12px;
  align-items: center;
  justify-content: flex-end;
  img {
    margin-right: 5px;
  }
`

export const StyledFooter = styled.div`
  padding: 12px 17px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`
export const StyledCompanyLogo = styled.img`
  max-width: 52px;
  max-height: 18px;
`
export const StyledTermLink = styled.a`
  color: #171b2b;
  margin-left: 11px;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.34px;
  text-decoration-line: underline;
  font-family: ${fontFamily.REGULAR};
`
export const DotPaddedText = styled.p`
  padding-left: 75px;
  position: relative;
  ::before {
    content: '..........';
    left: 0;
    top: 2px;
    line-height: 0;
    font-size: 41px;
    letter-spacing: -3.2px;
    position: absolute;
    font-family: serif;
    position: absolute;
  }
`
