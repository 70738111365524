import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import client from '../../api/client'
// import PopupShape from '../../images/preview-modal_shape.png'

import { useCompany } from '../../context/CompanyContext'
import { ReactComponent as CheckmarkIcon } from '../../images/CheckMarkNew.svg'
import { sendChatRequest } from '../../utils'
import { StyledClose, fontFamily } from '../common/styled'
import Form from './Form'

interface IProps {
  id: number
  title?: string
  collect?: string[]
  image?: string
  body?: string
  buttonText?: string
  buttonRedirect?: string
  seconds?: number
  event?: string
  customClass?: string
  textColor?: string
  backgroundColor?: string
  buttonBackgroundColor?: string
  buttonTextColor?: string
  displaySuccessMessage?: boolean
}

interface IState {
  details: {
    visible: boolean
    buttonClicked: boolean
    type?: string
    seconds?: number
    event?: string
    formSubmitted: boolean
    submitting: boolean
    customClass?: string
    formData: {
      name?: string
      phone?: string
      email?: string
      message?: string
    }
    response: {
      success?: boolean
      header?: string
      message_body?: string
      message_title?: string
    }
  }
  collection?: {}
}

const StyledPopup = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontFamily.REGULAR};
  backdrop-filter: blur(3px);
`
const StyledContent = styled.div`
  width: 998px;
  max-width: 90%;
  background: #ffffff;
  border-radius: 8px;
  height: max-content;
  box-shadow: 2px 5px 13px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 8px;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  max-height: 90vh;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.color || '#fff'};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
const StyledPopupWrapper = styled.div<{ background?: string }>`
  display: grid;
  background-color: ${(props) => props?.background ?? '#f38c39'};
  grid-template-columns: 1fr 1.2fr;
  position: relative;
  color: ${(props) => props?.color ?? ''};
  &.empty-image {
    grid-template-columns: 1fr;
    .preview-modal_shape {
      display: none;
    }
    .popup_curve_shape {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr; */

    /* .popup_image{
      display: none;
    } */
  }
`
const StyledPopupFigure = styled.div`
  margin: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
// const StyledPopupShape = styled.div`
//   height: 100%;
//   width: 80px;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: -74px;
//   background-image:url('${ShapePath}');
//   background-size: contain;
//   background-repeat: repeat-y;
//   background-position: right center;
//   overflow: hidden;
//   };
// `
const StyledPopupContent = styled.div`
  overflow: hidden;
  padding: 1.5rem;
  position: relative;
`
const StyledPopupBodyContent = styled.div`
  max-width: 477px;
  margin: auto;
`
const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`
const StyledLogo = styled.img`
  max-height: 40px;
`
const StyledImage = styled.img`
  width: 176px;
  height: 176px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`
const StyledTitle = styled.h1`
  text-align: center;
  line-height: 48px;
  text-align: center;
  letter-spacing: initial;
  font-family: ${fontFamily.SEMIBOLD} !important;
  font-size: clamp(1.5rem, 4vw, 48px) !important;
  max-width: 477px;
  margin: auto;
  color: ${(props) => props.color || '#000000'} !important;

  @media (max-width: 1024px) {
    line-height: 40px;
  }

  @media (max-width: 768px) {
    line-height: 32px;
  }
`
const PopupCloseIcon = styled(StyledClose)`
  top: 34px;
  right: 17px;
  color: ${(props) => props.color || '#000000'};
  padding: 10px !important;
  @media screen and (max-width: 768px) {
    right: 5px;
  }
`

const StyledBody = styled.div`
  font-size: clamp(1rem, 4vw, 20px);
  text-align: center;
  font-family: var(--ff-semibold);
  margin: 1.5rem 0;
  font-size: clamp(1rem, 4vw, 20px);
  text-align: center;
  font-family: ${fontFamily.SEMIBOLD};
  margin: 1.5rem 0;
  color: ${(props) => props.color || '#000000'};
  word-wrap: break-word;
`

const StyledResponse = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 48px auto;
`

const StyledResponseHeader = styled.h2`
  color: #1649d2 !important;
  text-align: center;
  font-family: ${fontFamily.BOLD} !important;
  font-size: 34px;
  text-align: center
  margin: 24px 0 !important;
  line-height: 52px;
`

const StyledResponseBody = styled.p`
  color: ${(props) => props.color || '#171b2b'} !important;
  font-size: 14px !important;
  text-align: center;
  margin: 20px 52px !important;
`

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
`

const Popup = (props: IProps) => {
  const company = useCompany()
  const [cookies, setCookie] = useCookies()

  const [showBodyImage, setShowBodyImage] = useState(false)

  const [popup, setPopup] = useState<IState['details']>({
    visible: false,
    buttonClicked: false,
    formSubmitted: false,
    submitting: false,
    formData: {},
    response: {
      success: false,
    },
  })
  const appSetting =
    window.fcs || window.fluidChatSettings || window.fluidSettings
  appSetting.show_popup = false

  const handleClick = async () => {
    setPopup({ ...popup, visible: false, buttonClicked: false })
    try {
      await sendChatRequest(appSetting)
      setCookie('hide_fluid_popup_' + props.id, true, {
        path: '/',
        maxAge: 15 * 86400,
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const handleSubmit = (data: IState['details']['formData']) => {
    setPopup((prevPopup) => ({
      ...prevPopup,
      submitting: true,
      formData: data,
    }))

    if (!popup.formSubmitted) {
      const params = {
        popup_id: props.id,
        affiliate: company?.affiliate,
        visitor: company?.visitor,
        contact: {
          full_name: data.name,
          email: data.email,
          phone: data.phone,
        },
        message: data.message,
      }

      client
        .post(company?.apiUrlHost + 'api/company/chat/popup.json', params, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          const result = response.data

          if (result.success) {
            setPopup({
              ...popup,
              submitting: false,
              formSubmitted: true,
              response: result,
            })

            if (
              props.buttonRedirect &&
              typeof props.buttonRedirect === 'string'
            ) {
              setTimeout(function () {
                window.location.href = props.buttonRedirect
                  ? props.buttonRedirect
                  : ''
              }, 1000)
            }

            if (!props?.displaySuccessMessage) handleClick()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  const onFluidChatPopupButtonClick = (e: CustomEvent) => {
    const popupId = e.detail.id

    if (parseInt(popupId) === props.id) {
      setPopup({ ...popup, visible: true, buttonClicked: true })
    }
  }

  useEffect(() => {
    const popupProps: Partial<IState['details']> = {}
    if (props.seconds) {
      popupProps.seconds = props.seconds
    } else {
      popupProps.visible = true
    }

    window.addEventListener(
      'FluidChatPopupButtonClick',
      onFluidChatPopupButtonClick,
    )

    setPopup({ ...popup, ...popupProps })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!popup.visible && props.event === 'timer' && popup.seconds) {
      setTimeout(() => {
        setPopup({ ...popup, visible: true })
      }, popup.seconds * 1000)
    }
    if (!popup.visible && props.event === 'click' && props?.customClass) {
      const el = document.querySelector(`.${props?.customClass}`)
      if (el) {
        el.addEventListener('click', () => {
          setPopup({ ...popup, visible: true })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowBodyImage(true)
      } else {
        setShowBodyImage(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const ImageElement = () => (
    <StyledPopupFigure
      className="popup_image"
      style={{
        marginBottom: showBodyImage ? '1.5rem' : '',
      }}
    >
      {props.image && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledImage src={props.image}></StyledImage>
        </div>
      )}
    </StyledPopupFigure>
  )

  return (
    <>
      {(popup.buttonClicked || !cookies['hide_fluid_popup_' + props.id]) &&
        popup.visible && (
          <StyledPopup
            id={'fluid-popup-' + props.id}
            style={{ zIndex: 999999 - (props.seconds ? props.seconds : 1000) }}
          >
            <StyledContent color={company?.color}>
              <StyledPopupWrapper
                className={props?.image ? '' : 'empty-image'}
                color={props?.textColor}
                background={props?.backgroundColor}
              >
                {!showBodyImage && <ImageElement />}
                <StyledPopupContent className="popup_content">
                  {/* <StyledPopupShape>
                    <img src={PopupShape} alt="shape" />
                  </StyledPopupShape> */}
                  <StyledPopupBodyContent data-cy="popup-content">
                    <StyledHeader>
                      {company?.logo && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <StyledLogo
                            data-cy="company-logo"
                            src={company.logo}
                            alt="Logo"
                          />
                        </div>
                      )}
                    </StyledHeader>
                    {showBodyImage && <ImageElement />}
                    <PopupCloseIcon
                      data-cy="popup-close"
                      onClick={handleClick}
                      color={props?.buttonBackgroundColor}
                    ></PopupCloseIcon>

                    {popup.formSubmitted && popup.response.success ? (
                      <StyledResponse>
                        <StyledIconWrapper>
                          <CheckmarkIcon></CheckmarkIcon>
                        </StyledIconWrapper>

                        <div>
                          <StyledResponseHeader>
                            {popup.response.message_title}
                          </StyledResponseHeader>
                          <StyledResponseBody color={props?.textColor}>
                            {popup.response.message_body}
                          </StyledResponseBody>
                        </div>
                      </StyledResponse>
                    ) : (
                      <>
                        <StyledTitle
                          data-cy="popup-title"
                          color={props?.textColor}
                        >
                          {props.title}
                        </StyledTitle>
                        <StyledBody
                          data-cy="popup-body"
                          color={props?.textColor}
                          dangerouslySetInnerHTML={{
                            __html: props.body ? props.body : '',
                          }}
                        />
                        <Form
                          collect={props.collect}
                          handleSubmit={handleSubmit}
                          submitting={popup.submitting}
                          formSubmitted={popup.formSubmitted}
                          buttonText={props.buttonText}
                          textColor={props?.textColor}
                          backgroundColor={props?.backgroundColor}
                          buttonBackgroundColor={props?.buttonBackgroundColor}
                          buttonTextColor={props?.buttonTextColor}
                        />
                      </>
                    )}
                  </StyledPopupBodyContent>
                </StyledPopupContent>
              </StyledPopupWrapper>
            </StyledContent>
          </StyledPopup>
        )}
    </>
  )
}

export default Popup
