import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import packageJson from '../../../package.json'

declare global {
  interface Window {
    appVersion: string
  }
}
window.appVersion = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

function refreshCacheAndReload() {
  if (caches) {
    caches.keys().then((names) => {
      for (let name of names) caches.delete(name)
    })
  }
  window.location.reload()
}

const CacheBuster: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLatestVersion, setIsLatestVersion] = useState(false)
  const [lastChecked, setLastChecked] = useState(Date.now())

  const checkForVersionMismatch = useCallback(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version as string
        const currentVersion = window.appVersion
        console.log({ latestVersion, currentVersion })
        if (semverGreaterThan(latestVersion, currentVersion)) {
          refreshCacheAndReload()
        } else {
          setIsLatestVersion(true)
        }
      })
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_ENV !== 'production') return
    const intervalID = setInterval(() => {
      setLastChecked(Date.now())
    }, 36e5 * 6) // check every 6 hours

    checkForVersionMismatch()
    return () => clearInterval(intervalID)
  }, [checkForVersionMismatch])

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_ENV !== 'production') return
    if (Date.now() - lastChecked > 36e5 * 6) {
      // check if older than 5.5 hours
      checkForVersionMismatch()
    }
  }, [checkForVersionMismatch, lastChecked])

  console.log(isLatestVersion)
  // if (isLatestVersion || process.env.REACT_APP_BUILD_ENV !== 'production')
  return <>{children}</>
  // return null
}

export default CacheBuster
