import { useEffect, useState } from 'react'
import { useTransition } from 'react-spring'

import { useCompany } from '../../../context/CompanyContext'
import CompanyAffiliateLogo from './CompanyAffiliateLogo'
import {
  StyledAnimatedDiv,
  StyledCloseIcon,
  StyledGreeting,
  StyledMessageHead,
  StyledMiniModal,
  StyledName,
  StyledTriangle,
} from './styled'
import { IProps } from './types'

const Prompt = (props: IProps) => {
  const company = useCompany()
  const handleClick = () => {
    props.hidePrompt()
  }

  const [isVisible, setIsVisible] = useState(false)

  const animationDelay = 700

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, animationDelay)

    return () => {
      clearTimeout(timer)
      setIsVisible(false)
    }
  }, [])

  const slideUp = useTransition(isVisible, {
    from: { y: 30, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 30, opacity: 0 },
  })

  return isVisible ? (
    slideUp(
      (style, item) =>
        item && (
          <StyledAnimatedDiv style={style}>
            <StyledCloseIcon onClick={handleClick}>Close</StyledCloseIcon>
            <StyledMiniModal>
              <StyledMessageHead>
                <CompanyAffiliateLogo />
                <div>
                  <StyledName>{company?.chat?.name}</StyledName>
                  <StyledGreeting id="chat-greeting">
                    {company?.chat?.message}
                  </StyledGreeting>
                </div>
              </StyledMessageHead>
              <StyledTriangle></StyledTriangle>
            </StyledMiniModal>
          </StyledAnimatedDiv>
        ),
    )
  ) : (
    <></>
  )
}

export default Prompt
