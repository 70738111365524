export const getTotal = ({
  price,
  shipping,
  tax,
  memberFee = 0,
}: {
  price: number
  shipping: number
  tax: number
  memberFee?: number
}) => {
  const sum = price + shipping + tax + memberFee
  return (isNaN(sum) ? 0.0 : sum).toLocaleString()
}
