import { lazy, Suspense, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useTransition } from 'react-spring'
import { useCompany } from '../../context/CompanyContext'
import Prompt from './Prompt'

import {
  BubbleIcon,
  StyledAnimatedMessageButton,
  StyledChatCloseImage,
  StyledChatIcon,
  StyledContainer,
} from './styled'

const Modal = lazy(() => import('./Modal'))

const Chat = function (props: any) {
  const [cookies] = useCookies()
  const company = useCompany()
  const fadeInBubble = useTransition(props.isVisibleChatBubble, {
    from: { x: 30, opacity: 0 },
    enter: { x: 0, opacity: 1, delay: 400 },
    leave: { x: 30, opacity: 0 },
  })

  useEffect(() => {
    props.setVisibleChatBubble(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cartItemPresent =
    (cookies.cartItemsLength && cookies.cartItemsLength !== '0') ||
    (company?.cart?.cart_token && company.cart?.items_count)
  return (
    <StyledContainer className={company?.chatOpen ? 'fluid-chat-open' : ''}>
      {company?.prompt && !cartItemPresent && (
        <Prompt hidePrompt={props.hidePrompt}></Prompt>
      )}

      {company?.chatOpen && (
        <Suspense fallback="">
          <Modal
            handleSubmit={props.handleSubmit}
            handleModalClose={props.closeModal}
          ></Modal>
        </Suspense>
      )}
      {fadeInBubble((style, item) => {
        return item ? (
          <StyledAnimatedMessageButton
            onClick={props.onClickHandler}
            style={style}
            color={company?.color}
          >
            {company?.chatIcon && (
              <BubbleIcon src={company?.chatIcon} alt="Open Chat" />
            )}
            {company?.chatCloseIcon && (
              <StyledChatCloseImage
                src={company?.chatCloseIcon}
                alt="Close Chat"
              />
            )}
            {!!!company?.chatIcon && <StyledChatIcon></StyledChatIcon>}
          </StyledAnimatedMessageButton>
        ) : (
          ''
        )
      })}
    </StyledContainer>
  )
}

export default Chat
