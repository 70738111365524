import client from '../../api/client'
import { useCompany } from '../../context/CompanyContext'
import { BannerCloseIcon, StyledBanner, StyledLink, StyledText } from './styled'
import { IProps } from './types'

const Banner = (props: IProps) => {
  const handleClick = () => {
    props.hideBanner()
  }

  const company = useCompany()
  const appSetting =
    window.fcs || window.fluidChatSettings || window.fluidSettings
  const apiHost = appSetting.api_url_host

  const handleLinkClick = async (params: any) => {
    if (company) {
      try {
        await client.post(`${apiHost}/custom_activities`, {
          custom_activity: {
            name: 'banner',
            visitor: company.visitor,
            affiliate: company.affiliate?.id,

            parameters: {},
          },
        })
        window.location.href = params
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <StyledBanner color={props.color} id="fluid-script-banner">
      <StyledText color={props.textColor}>
        {props.body}
        {props.buttonUrl && (
          <StyledLink
            // href={props.buttonUrl}
            color={props.textColor}
            onClick={() => handleLinkClick(props?.buttonUrl)}
          >
            {props.buttonText || 'Learn More'} »
          </StyledLink>
        )}
      </StyledText>
      <BannerCloseIcon
        onClick={handleClick}
        color={props.textColor}
      ></BannerCloseIcon>
    </StyledBanner>
  )
}

export default Banner
