// should use this instead: new Intl.NumberFormat

export const formatPrice = (price: number) => {
  if (!price) {
    price = 0.0
  }
  let parsedPrice = typeof price === 'string' ? price : price.toLocaleString()
  let splitPrice = parsedPrice.split('.')

  if (splitPrice.length === 1) return `${parsedPrice}.00`

  return splitPrice[1]?.length === 1 ? `${parsedPrice}0` : parsedPrice
}
