import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useCookies } from 'react-cookie'
import { ILocale, LocaleLanguage } from '../types/Locale'

const LanguageFields: Record<string, ILocale> = {
  en: require('../locale/en.json'),
  ja: require('../locale/ja.json'),
  es: require('../locale/es.json'),
  ko: require('../locale/ko.json'),
  pt: require('../locale/pt.json'),
  de: require('../locale/de.json'),
  he: require('../locale/he.json'),
  hu: require('../locale/hu.json'),
  id: require('../locale/id.json'),
  it: require('../locale/it.json'),
  nl: require('../locale/nl.json'),
  ru: require('../locale/ru.json'),
  tl: require('../locale/tl.json'),
  th: require('../locale/th.json'),
  fr: require('../locale/fr.json'),
  zh: require('../locale/zh_CN.json'),
  el: require('../locale/el.json'),
}

const LocaleContext = createContext<{
  locale: ILocale
  localeLanguage: keyof typeof LanguageFields
  setLocaleLanguage: Dispatch<SetStateAction<string>>
  availableLanguages: LocaleLanguage[]
  setAvailableLanguages: Dispatch<SetStateAction<LocaleLanguage[]>>
}>({
  locale: LanguageFields.en,
  localeLanguage: 'en',
  setLocaleLanguage: () => {},
  availableLanguages: [],
  setAvailableLanguages: () => {},
})

const LocaleProvider: React.FC = ({ children }) => {
  const [cookies] = useCookies()
  const [localeLanguage, setLocaleLanguage] = useState(cookies.locale || 'en')
  const [availableLanguages, setAvailableLanguages] = useState<
    LocaleLanguage[]
  >([])

  const value = useMemo(
    () => ({
      localeLanguage,
      locale: LanguageFields[localeLanguage],
      setLocaleLanguage,
      availableLanguages,
      setAvailableLanguages,
    }),
    [
      localeLanguage,
      setLocaleLanguage,
      setAvailableLanguages,
      availableLanguages,
    ],
  )

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}

const useLocale = () => {
  const context = useContext(LocaleContext)
  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }
  return context
}

export { LocaleProvider, useLocale }
