import apiRoutes from '../../../api-routes'

interface ICartApiRoute {
  apiUrl: string
  method: 'get' | 'post'
  body?: object
}

export const cartItemApiRoute = (
  {
    variantId,
    productIds,
    cartToken,
    cartId,
    visitor,
    cart_type,
    affiliate,
    subscription,
    quantity,
    country_iso,
    enrollmentPackId,
    language_iso,
  }: {
    variantId?: number
    productIds?: number[]
    cartToken: string
    cartId: string
    visitor: string
    cart_type?: string
    affiliate?: string | number
    subscription?: boolean
    quantity?: string | number
    country_iso: string
    enrollmentPackId?: number
    language_iso: string
  },
  getItems: boolean = false,
): ICartApiRoute => {
  const isEnrollment = cart_type === 'enrollment'
  if (getItems) {
    return {
      apiUrl: `${apiRoutes.getCart}/${cartId}`,
      method: 'get',
      body: { params: { cart_token: cartToken } },
    }
  }

  if (cartToken && !productIds) {
    return {
      apiUrl: apiRoutes.getCartByToken(cartToken),
      method: 'get',
      body: { params: { visitor_token: visitor } },
    }
  }
  let params = {
    cart_item: {
      quantity: quantity || 1,
      product_id: productIds,
      product_ids: productIds,
      variant_id: variantId,
      subscription: subscription || false,
    },
    ...(isEnrollment ? { enrollment_pack_id: enrollmentPackId } : {}),
    visitor_token: visitor,
    ...(country_iso ? { country_iso } : {}),
    cart_token: cartToken,
    cart_type,
    affiliate,
    language_iso,
  }

  let apiMeta: ICartApiRoute = {
    apiUrl: apiRoutes.createCartItem,
    method: 'post',
  }

  if (productIds) {
    apiMeta.body = params
  } else if (cartId) {
    apiMeta = {
      apiUrl: `${apiRoutes.getCart}/${cartId}`,
      method: 'get',
      body: { params },
    }
  }
  return apiMeta
}
