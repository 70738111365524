import { ChangeEventHandler } from 'react'

export const formatCardDate: ChangeEventHandler<HTMLInputElement> = (e) => {
  const targetValue = e.target.value || ''
  let oldVal = e.target.dataset.oldVal
  e.target.dataset.oldVal = e.target.value
  if (targetValue.length < 2 || (oldVal || '').length > targetValue.length) {
    return targetValue
  }
  if (targetValue.indexOf('/') === -1 && targetValue.length === 2) {
    let value = parseInt(targetValue) > 12 ? 12 : targetValue
    e.target.value = `${value}/`
    return `${value}/`
  } else if (targetValue.length > 2) {
    if (targetValue.indexOf('/') === -1) {
      let month = targetValue.slice(0, 2)
      let year = targetValue.slice(2, 4)
      let dateVal = `${month}/${year}`
      e.target.value = dateVal
      e.target.dataset.oldVal = dateVal
      let cursorPos = dateVal.length
      e.target.setSelectionRange(cursorPos, cursorPos)
      return dateVal
    }
    let value = targetValue.split('/')
    let year = value[1] ? value[1] : ''
    let month = value[0]
    if (value[0]?.length > 2) {
      month = (oldVal || '').split('/')[0]
    }
    if ((oldVal || '').split('/')[0]?.length === 1) {
      e.target.setSelectionRange(3, 3)
    }
    e.target.value = `${month}/${year.slice(0, 2)}`
    e.target.dataset.oldVal = `${month}/${year.slice(0, 2)}`
    return `${month}/${year.slice(0, 2)}`
  }
}
