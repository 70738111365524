export const defaultCheckoutValue = (values: {
  address: any
  year: number
  email: string
  holdCardThirtyDays: boolean
  repeatOrder: boolean
  affiliate: any
  fulfillment: any
  month: number
  cardDate: string
  manual_calculation: boolean
  fullName: string
  number: string
  verificationValue: string
  country: { iso: string }
  shippingFullName: string
}) => {
  const address = values.address || {}
  const cardExpYear = String(values.year).slice(2)
  return {
    email: values.email,
    terms_and_agreement: true,
    hold_card_30_days: values.holdCardThirtyDays,
    repeat_order: values.repeatOrder,
    affiliate: values.affiliate,
    fulfillment: values.fulfillment,
    manual_calculation: values?.manual_calculation,
    card_date:
      values.month && values.year
        ? `${values.month}/${cardExpYear}`
        : values?.cardDate || null,
    credit_card: {
      full_name: values.fullName,
      number: values.number,
      verification_value: values.verificationValue,
      shipping_country: values.country?.iso,
      shipping_address1: address.address1,
      shipping_address2: address.address2,
      shipping_city: address.city,
      shipping_zip: address.postal_code,
      shipping_state: address.state,
    },
    data: {
      full_name: values.shippingFullName,
    },
  }
}
