import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'

import App from './App'
import CacheBuster from './components/CacheBuster'
import reportWebVitals from './reportWebVitals'

window.addEventListener('load', contentLoader)
window.addEventListener('turbo:load', contentLoader)

let chatRendered = false

function contentLoader() {
  if (chatRendered) return

  const rootNode = document.querySelector('.fluid-chat-app')
  if (rootNode) {
    rootNode.remove()
  }
  const elem = document.createElement('div')
  elem.classList.add('fluid-chat-app')
  document.body.appendChild(elem)

  ReactDOM.render(
    <React.StrictMode>
      <CacheBuster>
        <App />
        <Helmet>
          <script
            src="https://core.spreedly.com/iframe/iframe-v1.min.js"
            type="text/javascript"
          />
        </Helmet>
      </CacheBuster>
    </React.StrictMode>,
    elem,
  )

  /**
   * Add custom event on button based on attribute `data-fluidpopup-id`
   */

  document.querySelectorAll('[data-fluidpopup-id]').forEach((element) => {
    element.addEventListener('click', function (this: any) {
      let id = this.getAttribute('data-fluidpopup-id')
      window.dispatchEvent(
        new CustomEvent('FluidChatPopupButtonClick', {
          detail: { id },
        }),
      )
    })
  })

  document.querySelectorAll('[data-chat-with-me]').forEach((element) => {
    element.addEventListener('click', function (this: any) {
      window.dispatchEvent(new CustomEvent('FluidChatButtonClick'))
    })
  })

  /**
   * Add custom event on button based on attribute `data-fluid-enrollment`
   */

  document
    .querySelectorAll('[data-fluid-enrollment], [data-fluid-checkout]')
    .forEach((element) => {
      element.addEventListener('click', function (this: any, e: Event) {
        e.preventDefault()
        let ids = this.hasAttribute('data-fluid-enrollment')
          ? this.getAttribute('data-fluid-enrollment')
          : this.getAttribute('data-fluid-checkout')
        let isEnrollment = this.hasAttribute('data-fluid-enrollment')
          ? true
          : false

        window.dispatchEvent(
          new CustomEvent('FluidEnrollmentButtonClick', {
            detail: {
              ids: ids,
              isEnrollment: isEnrollment,
              enrollmentPackId: isEnrollment ? ids : null,
            },
          }),
        )
      })
    })

  document
    .querySelectorAll('[data-fluid-checkout-group]')
    .forEach((element: any) => {
      let button = element.querySelectorAll('[data-fluid-checkout-group-id]')[0]
      if (button) {
        button.addEventListener('click', function (this: any, e: Event) {
          e.preventDefault()

          let qty = element.querySelectorAll(
            'input[name="fluid-checkout-quantity"]',
          )[0].value
          let ids = this.getAttribute('data-fluid-checkout-group-id')
          const type =
            this.getAttribute('data-fluid-checkout-type') || 'product'

          let isSubscription = false
          const inputElements = document.querySelectorAll(
            'input[name="fluid-checkout-subscribe"]',
          )

          inputElements.forEach((input: any) => {
            if (input.checked && input.value === 'subscription') {
              isSubscription = true
            }
          })
          window.dispatchEvent(
            new CustomEvent('FluidEnrollmentButtonClick', {
              detail: {
                ids,
                isEnrollment: false,
                quantity: qty,
                isSubscription,
                type,
              },
            }),
          )
        })
      }
    })

  document
    .querySelectorAll('[data-fluid-subscription-checkout]')
    .forEach((element) => {
      element.addEventListener('click', function (this: any, e: Event) {
        e.preventDefault()
        let ids = this.getAttribute('data-fluid-subscription-checkout')
        window.dispatchEvent(
          new CustomEvent('FluidEnrollmentButtonClick', {
            detail: { ids: ids, isEnrollment: false, isSubscription: true },
          }),
        )
      })
    })

  chatRendered = true
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
